import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsFillClockFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import SweetAlert from "react-bootstrap-sweetalert";
import Wrapper from "../../../Utlilities/Wrapper";
import useGetPosts from "../../../Hooks/useGetPosts";
import moment from "moment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import posted from "../components/posted.svg";

import Immediate from "../components/Immediate_project-listing-screen.svg";
import Notimmediate from "../components/Not-Immediate_project-listing-screen.svg";
import locationLogo from "../components/location.svg";
import light from "../components/light.svg";
import "./modal.scss";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import useSendFormData from "src/Hooks/useSendFormData";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  MDBCollapse,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';

function ProjectDetail() {

   const allowedFileTypes = [
        'image/jpeg',
        'image/webp',
        'image/png',
        'video/webm',
        'audio/mp3',
        'video/mp4',
        'application/doc',
        'application/pdf',
      ];

  const { id } = useParams();
  const [basicModal, setBasicModal] = useState(false);
  //const [isCollapse, setIsCollapse] = useState(false);
  const [serviceDivData, setServiceDivData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [serviceIdError, setServiceIdError] = useState('');
  const hiddenFileInput = useRef(null);
  const formRef = useRef(null);
  const [proposalFiles, setProposalFiles] = useState([])
  const [isSubmit, setIsSubmit] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ variant: false, message: '', status: false });

  const {sendForm:saveProposal}= useSendFormData("vendor_proposal_submit");
  const { data: serviceList } = useGetPosts({ url: 'vendor_service_list' });
  const toggleShow = () => { setBasicModal(!basicModal); }

  const getState = useSelector((state) => state);
  const navigate = useNavigate();


  const checkStatus = () => {
    if (!getState?.userSignin?.isLoggedIn) {
      navigate("/login");
    }
    else
    {
      toggleShow();
    }
  };

  const toggleCollapse = (data, index) => {
    let newData = [...serviceDivData];
    newData[index].isCollapse = !newData[index].isCollapse;
    setServiceDivData(newData);
  };
  
  useEffect(() => {
    console.log('serviceList', serviceList?.data);
  }, [serviceList]);


  const bodyData = {
    _id: id,
  };
  const project = useGetPosts({ url: 'project_details', data: bodyData });

  let ratingLoop = Array.apply(null, {
    length: project?.data?.total_rating,
  }).map(Number.call, Number);
  const wrapperHeight = "13vh";
  // console.log(project?.data?.data.location, "this is the data");

  const showOpenFileDlg = (e) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  }


  const handleFileRead = (e)=>{
    let selected_images = []
    Object.values(e.target.files).forEach(async (value) => {
    console.log(value, 'xxxxxxxx')

    if (allowedFileTypes.includes(value.type)) {
      selected_images.push(value)        
    } 
    else {        
      alert('Invalid file type. Please select allowed file types .doc , .jpeg , .webm , .png , .web , .mp3 , .mp4 , .pdf');  
    }})
    setProposalFiles(selected_images)
  }

  const addService = (e) => {
    //const lastData = serviceDivData[serviceDivData.length-1]??0;
    let divData = { id: 0, isCollapse: true };
    let newFormData = { title: '', description: '', budget: '' };
    setServiceDivData([...serviceDivData, divData]);
    setFormData([...formData, newFormData]);
  }

  const deleteService = (data, index) => {
    if (serviceDivData.length === 1) return;
    let newData = [...serviceDivData];
    let newFormData = [...formData];
    newData.splice(index, 1);
    newFormData.splice(index, 1);
    setServiceDivData(newData);
    setFormData(newFormData);
  }

  const handleChange = (e, i) => {
    let newFormData = [...formData];
    newFormData[i][e.target.name] = e.target.value;
    delete newFormData[i].error;
    setFormData(newFormData);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = false;

    if (title.length === 0) {
      setTitleError('Title is required!');
      setIsSubmit(false); 
      isValid = false;
    } else {
      setIsSubmit(true);
      isValid = true;
    }

    if (description.length === 0) {
      setDescriptionError('Description is required!');
      setIsSubmit(false);
      isValid = false;
    } else {
      setIsSubmit(true);
      isValid = true;
    }
    if (serviceId.length === 0) {
      setServiceIdError('Service is required!');
      setIsSubmit(false);
      isValid = false;
    } else {
      setIsSubmit(true);
      isValid = true;
    }
    if (formData.length < 1) {
      setServiceIdError('At least one line item required!');
      setIsSubmit(false);
      isValid = false;
    } else {
      setIsSubmit(true);
      isValid = true;
    }

    for (let i = 0; i < formData.length; i++) {
      let error = {};
      if (formData[i].title.length === 0) {
        error.title = 'Title is required!';
      }
      if (formData[i].description.length === 0) {
        error.description = 'Description is required!';
      }
      if (formData[i].budget.length === 0) {
        error.budget = 'Rate is required!';
      }
      let newFormData = [...formData];
      if (Object.keys(error).length > 0) {
        newFormData[i]['error'] = error;
        setFormData(newFormData);
      }
    }
    let isError = false;
    formData.forEach(item => {
      isError = 'error' in item;
    });
    console.log(proposalFiles, 'proposalFiles')
    if (isValid && !isError) {
      let submitData = new FormData()
      submitData.append('project_id', id)
      submitData.append('service_id', serviceId)
      submitData.append('description', description)
      submitData.append('title', title)
      console.log(formData,'fd');      
      submitData.append('line_items', JSON.stringify(formData))
      let budget_sum = 0;
      formData.forEach((obj, index) => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            submitData.append(`line_items[${index}].${key}`, obj[key]);    
            if(key=='budget' || key=='Budget')
            {
              budget_sum += parseInt(obj[key]); // Accumulate the budget_sum
              //console.log("Key found Value ",obj[key]);
            }
          }
        }
      });

      submitData.append('budget', budget_sum)

      proposalFiles.map((image)=>{
        console.log(image, 'singleee')
        submitData.append('images', image)
      })
      
      const result=await saveProposal(submitData)
      if (result.status) {
        setBasicModal(false);
        setAlertMessage({ variant: 'success', message: 'Proposal submitted', status: true });
      }
    }
  }

  return (
    <>
      <SweetAlert
        success
        title={alertMessage.message}
        show={alertMessage.status}
        onConfirm={() => {
          setAlertMessage({ variant: false, message: '', status: false });
          setIsSubmit(false);
          setTitle('');
          setDescription('');
          setServiceDivData([]);
          setFormData([]);
        }}
      >
        Successfully
      </SweetAlert>
      <Wrapper wrapperHeight={wrapperHeight} />
      <Container fluid>
        <Row>
          <div id="top-section-row" className="shadow-sm">
            <Container>
              <Row style={{ display: "flex", alignItems: "center" }}>
                <Col md={2} xs={1} style={{ padding: "32px" }}>
                  <div className="project-card-avatar">
                    <div className="rightSideofCard">
                      <img alt="" src="https://images.unsplash.com/photo-1470207086513-4657d9988b08?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MnwyMzY4MDB8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60" />
                    </div>
                  </div>
                </Col>
                <Col md={7} xs={9}>
                  <div className="title-desc-card2">
                    <p>{project?.data?.data?.title}</p>
                    <p>{project?.data?.data.description}</p>

                    <div className="iconContainer">
                      <div>
                        <img src={light} alt="" />
                        <span style={{ marginLeft: "2px" }}>
                          {project?.data?.data.street_name}
                        </span>
                      </div>
                      <div>
                        <img src={locationLogo} />
                        <span>{project?.data?.data.location}</span>
                      </div>
                      <div>
                        {project?.data?.data.is_immidieat ? (
                          <>
                            <img src={Immediate} />
                            <span>Immediate</span>
                          </>
                        ) : (
                          <>
                            <img src={Notimmediate} />
                            <span>Not Immediate</span>
                          </>
                        )}
                      </div>
                      <div>
                        <img src={posted} />

                        <span>
                          {moment(project?.data?.data.created_at).fromNow()}
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Row>
        <Row>
          <Col md={8} sm={8}>
            {/* left section */}
            <Container>
              <Row>
                <Col md={12} xs={12}>
                  <div className="about-div">
                    <p id="descript">Project Description</p>
                    <p>{project?.data?.data.description}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={12}>
                  <div className="map-on-project-detail">
                    <p className="location-title">Location</p>

                    <iframe
                      title="google-map-on-project-detail"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115681.29592731265!2d-77.47713270775661!3d25.0326996781907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x892f7c99b981dbc9%3A0x2aef01d3485e50d2!2sNassau%2C%20Bahamy!5e0!3m2!1spl!2spl!4v1624445118063!5m2!1spl!2spl"
                      className="w-100"
                      height="400"
                      loading="lazy"
                    ></iframe>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>

          {/* right side section */}
          <Col md={4} xs={4}>
            <Container fluid>
              <Row>
                <Col md={12} xs={12} style={{ marginTop: "68px" }}>
                  <div className="apply-now-btn-div">
                    <button  onClick={() => {          
          checkStatus();
        }}>Submit Proposal</button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={12}>
                  <div className="project-summary-div">
                    <div className="project-summary-title">
                      <h3>Job Summary</h3>
                    </div>
                    <div className="project-location-div locationContainer">
                      <div>
                        <LocationOnIcon />
                        <p>Location</p>
                      </div>
                      <div style={{ marginLeft: "27px" }}>
                        <span>{project?.data?.data.location}</span>
                      </div>
                    </div>
                    <div className="project-location-div">
                      <div className="postedIcon">
                        <BsFillClockFill
                          style={{ width: "20px", height: "15px" }}
                        />
                        <p>Date Posted</p>
                      </div>
                      <div>
                        <span style={{ marginLeft: "42px" }}>
                          {moment(project?.data?.created_at).fromNow()}
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1' className="custom-modal" onHide={() => {
        setTitle('');
        setTitleError('');
        setDescription('');
        setDescriptionError('');
        setServiceId('');
        setServiceIdError('');
        setServiceDivData([]);
        setFormData([]);
      }} staticBackdrop>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Submit Proposal</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {alertMessage.variant && <Alert key={alertMessage.variant} variant={alertMessage.variant}>
                {alertMessage.message}
              </Alert>}
              <form onSubmit={handleSubmit} className="has-validated" enctype="multipart/form-data">
                <p>
                  Proposal Title
                </p>
                <Row>

                  <Col md={12} sm={12} className="">
                    
                    <input type="text" className={`form-control ${setTitleError ? '' : ''}`} onChange={(e) => { setTitle(e.target.value); setTitleError(''); }} value={title} name="title" placeholder="Enter Proposal Title" />
                    {titleError ? <div className="invalid-feedback">
                      {titleError}
                    </div> : ''}

                  </Col>

                </Row>
                <p className="mt-3">
                  Proposal Description
                </p>
                <Row >
                  <Col md={12} sm={12} className="">
                    {/* <div className="city-dropdown streetfeild"> */}
                    <textarea className={`form-control ${descriptionError ? 'is-invalid' : ''}`} name="proposal_description" onChange={(e) => { setDescription(e.target.value); setDescriptionError(''); }} value={description} placeholder="Enter Proposal Description" rows="4" />
                    {/* </div> */}
                    {descriptionError ? <div className="invalid-feedback">
                      {descriptionError}
                    </div> : ''}
                  </Col>
                </Row>

                <label className="form-label mt-3">Shared Service Profile</label>
                <Row>
                  <Col md={12} sm={12} className="">
                    <select className={`form-select ${serviceIdError ? 'is-invalid' : ''}`} name="service_id" value={serviceId}
                      onChange={(e) => { setServiceId(e.target.value); setServiceIdError(''); }}>
                      <option defaultValue={true}>Select Service</option>                      
                      {getState?.userSignin?.isLoggedIn && // Check if the user is not logged in
                      serviceList?.data.map((item, i) => (
                        <option value={item.category} key={i}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                    {serviceIdError ? 
                      <div className="invalid-feedback">
                        {serviceIdError}
                      </div> : ''
                    }
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col md={6} sm={6}>
                    <p className="fw-bold">Offering Line Items</p>
                  </Col>
                  <Col md={6} sm={6}>
                    <MDBBtn color='none' tag='a' rippleColor='dark' className="float-end" onClick={addService}>
                      <AddCircleOutlineOutlinedIcon /> Add Line Items
                    </MDBBtn>
                  </Col>
                </Row>

                <div id="add-services">
                  {/* <ServiceComponent /> */}
                  {serviceDivData.map((item, index) => {
                    //return <ServiceComponent item={item} key={index} index={index} />
                    return <div className="border rounded-3 p-3 mb-2" key={index}>
                      <Row>
                        <Col md={6} sm={6}>
                          <MDBBtn color='none' tag='a' rippleColor='dark' onClick={(e) => toggleCollapse(item, index)}>
                            {formData[index].title !== '' ? formData[index].title : 'Add Line Items'}
                          </MDBBtn>
                        </Col>
                        <Col md={6} sm={6}>
                          <MDBBtn color='none' tag='a' rippleColor='dark' className="float-end ms-2" onClick={(e) => { deleteService(item, index); }}>
                            <DeleteForeverOutlinedIcon />
                          </MDBBtn>
                          <MDBBtn color='none' tag='a' rippleColor='dark' className="float-end ms-3" onClick={(e) => { toggleCollapse(item, index); }}>
                            <CreateOutlinedIcon />
                          </MDBBtn>
                          <span className="float-end fw-bold">${formData[index].budget ?? '0'}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <MDBCollapse show={item.isCollapse}>
                            <div className="pt-2">
                              <label className="fw-bold form-label">Name</label>
                              <input type="text" className={`form-control ${formData[index]?.error?.title ? 'is-invalid' : ''}`} placeholder="Enter Service Name" onChange={(e) => handleChange(e, index)} value={formData[index].title} name="title" />
                              <div className={`${formData[index]?.error?.title ? 'invalid-feedback' : ''}`}>
                                {formData[index]?.error?.title ?? ''}
                              </div>
                            </div>
                            <div className="pt-2">
                              <label className="fw-bold form-label">Description</label>
                              <textarea type="text" className={`form-control ${formData[index]?.error?.description ? 'is-invalid' : ''}`} placeholder="Enter Service Description" onChange={(e) => handleChange(e, index)} value={formData[index].description} name="description" />
                              <div className={`${formData[index]?.error?.description ? 'invalid-feedback' : ''}`}>
                                {formData[index]?.error?.description ?? ''}
                              </div>
                            </div>
                            <div className="pt-2 pb-1">
                              <label className="fw-bold form-label">Rate</label>
                              <input type="text" className={`form-control ${formData[index]?.error?.budget ? 'is-invalid' : ''}`} placeholder="Enter Service Rate" onChange={(e) => handleChange(e, index)} value={formData[index].budget} name="budget" />
                              <div className={`${formData[index]?.error?.budget ? 'invalid-feedback' : ''}`}>
                                {formData[index]?.error?.budget ?? ''}
                              </div>
                            </div>
                          </MDBCollapse>
                        </Col>
                      </Row>
                    </div>
                  })}
                </div>

                <Row className="mt-4">
                  <Col md={12} sm={12}>
                    <p className="fw-bold">Upload relevant document</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} sm={12}>
                  <MDBBtn outline className="" onClick={showOpenFileDlg}>
                      <AttachFileOutlinedIcon />
                        { proposalFiles.length > 0 ?
                        <>{proposalFiles.length} Files Selected</>
                        : <> Upload Document (5 MB) </>
                        } 
                    </MDBBtn>
                    <input type="file" multiple ref={hiddenFileInput} onChange={(e) => handleFileRead(e)} className="d-none" />
                  </Col>
                </Row>
                <input type="submit" ref={formRef} className="d-none" />
              </form>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn onClick={(e) => formRef.current.click()}>Submit Proposal</MDBBtn>
            </MDBModalFooter>

          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default ProjectDetail;
