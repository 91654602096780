import React, { useState } from "react";
import CategoryList from "./components/CategoryList";
import HeroSection from "./components/HeroSection";
import useFetch from "../../Hooks/useFetch";
import useFetchAndPost from "../../Hooks/useFetchAndPost";
import VendorCard from "./components/VendorCard";
import Wrapper from "../../Utlilities/Wrapper";

import rectangleplumber from "../../assets/images/rectangle.svg";
import plumber from "../../assets/images/plumber.png";
import housemaid from "../../assets/images/housemaid.png";
import cons from "../../assets/images/cons.png";
import rectangle from "../../assets/images/rectangle.svg";
import contact from "../../assets/images/contact.png";

function Home() {
  //csutom - fetch hook
  const { error, isPending, data } = useFetch(
    process.env.REACT_APP_URL + "/front/"
  );
  //const {data:data}=useGetPosts({url: 'front'})
  const wrapperHeight = "10vh";
  return (
<>
    <main className="main">

      <section id="hero" className="hero hero-section">
        <div className="container d-flex">
          <div className="text-content text-left">
            <div className="circle"></div>
            <h1>Geegr for <span>Service Providers and Needs</span></h1>
            <p className="with-dot"  data-aos-delay="100">  Got skills, got will? Join Geegr today and start turning your skills into dollars! </p>
            <p className="with-dot"  data-aos-delay="200">Leave the hard work to us. Find high paying gigs or top-notch home services without breaking a sweat.</p>
            <a href="/signup" className="btn-join"  data-aos-delay="300">Join Geegr</a>
          </div>
          <div className="images aos-init aos-animate" data-aos="zoom-out" data-aos-delay="300">
            <img src={rectangleplumber} className="rectangle-plumber"  alt="" />
            <img src={plumber} alt="Plumber" className="plumber" />
            <img src={housemaid} alt="Housemaid" className="housemaid" />
            <img src={cons} alt="Construction Worker" className="cons" />
            <img src={rectangle} className="rectangle"  alt="" />

          </div>
        </div>
      </section>

      <section id="services" className="services section">
        <div className=""></div>
        
        <div className="container paragraph-title" data-aos-delay="100" >
          <h2>What is Geegr?</h2>
        </div>
        <div className="container paragraph-section">
          <p>Geegr is a digital marketplace which brings together service providers with local customers.Through this marketplace every individual has the tools needed to make finding, hiring and paying for any service.</p>
        </div>
  
        <div className="container">
  
          <div className="row g-5">
  
            <div className="col-lg-6"  data-aos-delay="100">
              <div className="service-item geeger-section item-cyan position-relative">
                <div>
                  <h3>For Customers</h3>
                  <p>join Geegr to find qualified service providers in your area at the most competitive prices. Though our marketplace structure, you will never again be ripped off or be victim of supplier not delivering as promised.</p>
                    <a href="/signup" className="read-more stretched-link join-customer-btn">Join Geegr</a>
                </div>
              </div>
            </div>
  
            <div className="col-lg-6"  data-aos-delay="200">
              <div className="service-item geeger-section item-orange position-relative">
                <div>
                  <h3>For Vendors</h3>
                  <p>Join Geegr to find local customers for your service business. through our organization no job will ever go unpaid, and we will provide you with the tools needed to maximize your earning potential, so that you can focus on the job at hand.</p>
                  <a href="/signup" className="read-more stretched-link join-customer-btn">Join Geegr</a>

                </div>
              </div>
            </div>
  
          </div>

        </div>  
      </section>

      <section id="contact" className="contact section contact-main-container">

        
        <div className="container paragraph-title">
          <h2>Contact us</h2>
        </div>

        <div className="container paragraph-section">
          <p>We'd love to hear from you! Whether you have a question about our services, need assistance, or just want to provide feedback, our team is ready to assist you.</p>
        </div>

        <div className="color-contact"></div>

        <div className="container contact-container" data-aos-delay="100">
          <div className="contact-content row gy-4 mt-1">

            <div className="col-lg-6 contact-imgsection" data-aos-delay="300">
              <div>
                <img className="contact-img" src={contact} alt="" />
              </div>
            </div>

            <div className="col-lg-6 contact-form">
              <form action="forms/contact.php" method="post" className="php-email-form" data-aos-delay="400">
                <div className="row gy-4 contact-row">

                  <div className="col-md-6">
                    <label>First Name</label>
                    <input type="text" name="first_name" className="form-control" placeholder="Enter First Name" required="" />
                  </div>

                  <div className="col-md-6">
                    <label>Last Name</label>
                    <input type="text" className="form-control" name="last_name" placeholder="Enter Last Name" required="" />
                  </div>

                  <div className="col-md-12">
                    <input type="text" className="form-control" name="contact" placeholder="Enter Contact Number" required="" />
                  </div>

                  <div className="col-md-12">
                    <label>Email Id</label>
                    <input type="email" className="form-control" name="email" placeholder="Enter Email Id" required="" />
                  </div>

                  <div className="col-md-12">
                    <label>Message</label>
                    <textarea className="form-control" name="message" rows="6" placeholder="Write Something..." required="" />
                  </div>

                  <div className="col-md-12">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">Your message has been sent. Thank you!</div>
                    <button className="col-md-12" style={{ "border-radius": "3px" }}  type="submit">Send Message</button>
                  </div>

                </div>
              </form>
            </div>

          </div>
        </div>
      </section>
  
    </main>

    </>
  );
}

export default Home;
