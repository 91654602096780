import axios from "axios";
export const signin =
  ({ email, password }, setFieldError) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_URL + process.env.REACT_APP_LOGIN,
        {
          email,
          password,
        }
      );
      console.log('LoginData: ', data);
      sessionStorage.setItem("userInfo", JSON.stringify(data));
      
      dispatch({ type: "USER_SIGNIN_REQUEST", payload: data });
    } catch (error) {
      console.log('LoginErrorData: ', error);
      setFieldError('server', error.response?.data?.error);
      
      // dispatch({
      //   type: USER_SIGNIN_FAIL,
      //   payload:
      //     !!error.response && !!error.response.data.message
      //       ? error.response.data.message
      //       : error.message,
      // })
    }
  };

export const social_signin =
  ({ email, sub,social_login_type }, setFieldError) =>
  async (dispatch) => {
    try {

      const { data } = await axios.post(
        process.env.REACT_APP_URL + process.env.REACT_APP_SOCIAL_LOGIN,
        {
          email,
          sub,
          social_login_type,
        }
      );
      console.log('LoginData: ', data);

      sessionStorage.setItem("userInfo", JSON.stringify(data));
      dispatch({ type: "USER_SIGNIN_REQUEST", payload: data });
    } catch (error) {
      console.log('LoginErrorData: ', error.response.data.error);
      setFieldError('server', error.response.data.error);

      //setFieldError('server', error.response?.data?.error);
      // dispatch({
      //   type: USER_SIGNIN_FAIL,
      //   payload:
      //     !!error.response && !!error.response.data.message
      //       ? error.response.data.message
      //       : error.message,
      // })
    }
  };

export const signout = () => (dispatch) => {
  sessionStorage.removeItem("userInfo");
  dispatch({ type: "USER_SIGNOUT" });
};

export const getuserinfo = (newData) => (dispatch) => {  
  const storedData = sessionStorage.getItem("userInfo");  
  const userData = JSON.parse(storedData);  
  userData.data.user = { ...userData.data.user, ...newData };
  sessionStorage.setItem("userInfo", JSON.stringify(userData));
  // Dispatch an action to update the Redux store if needed
  dispatch({ type: "USER_UPDATE_REQUEST", payload: newData });
};

